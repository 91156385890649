


















































































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import {
  AttachmentHostType,
  AuditFlowScope,
  ExternalMonthlyPlanDto,
  ExternalProjectDetailDto,
  ExternalProjectSpecificPlanDto,
  OrganizationUnitDto,
  ProjectSpecificGoalDto,
} from "@/api/appService";
import api from "@/api";
import ProvinceCityAreaContent from "@/components/CustomForm/TableDetail/components/ProvinceCityAreaContent.vue";

@Component({
  name: "ExternalMonthlyPlanDetail",
  components: {
    ProvinceCityAreaContent,
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },

  filters: {
    getSpecificGoal(goalId: string, list: ProjectSpecificGoalDto[]) {
      if (goalId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ProjectSpecificGoalDto) => {
            if (effectItem.id == goalId) {
              effect = effectItem.effect;
              return;
            }
          });
        }
        return effect;
      }
    },

    getSpecificPlan(planId: string, list: ExternalProjectSpecificPlanDto[]) {
      if (planId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ExternalProjectSpecificPlanDto) => {
            if (effectItem.id == planId) {
              effect = effectItem.title;
              return;
            }
          });
        }
        return effect;
      }
    },
  },
})
export default class ExternalMonthlyPlanDetail extends Vue {

  detailId?: number;
  detail: ExternalMonthlyPlanDto = {
    project: {},
  };
  unitList?: OrganizationUnitDto[] = [];
  selector = "table-detail";
  filename = "项目月计划表";
  styles = "";
  effects: ProjectSpecificGoalDto[] = [];
  specificPlans: ExternalProjectSpecificPlanDto[] = [];
  externalProject: ExternalProjectDetailDto = {
    effects: this.effects,
    specificPlans: this.specificPlans,
  };

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  getValue(
    provinceId: string | number,
    cityId: string | number,
    areaId: string | number,
    townId: string | number
  ) {
    let valueData = {
      provinceId: provinceId,
      cityId: cityId,
      areaId: areaId,
      townId: townId,
    };
    return valueData;
  }

  fetchData() {
    return api.externalMonthlyPlanService.audit;
  }

  get auditApi(){
    return {
      canAudit: api.externalMonthlyPlanService.canAudit,
      userAudit: api.externalMonthlyPlanService.audit
    } as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalMonthlyPlanService
      .get({ id: this.detailId })
      .then((res) => {
        let resData = res;
        this.getProjectDetail(res.project!.id);
        this.detail = { ...resData };
      });
  }



  getProjectDetail(projectId: any) {
    api.externalProject
      .getDetailByProjectId({ projectId: projectId })
      .then((res) => {
        this.externalProject = res;
        // this.goalList = res.effects!;
        // var other = this.goalList.push({ id: "", effect: "其他" });
        // //
        // this.specificPlanList = res.specificPlans!;
      });
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
