



























































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import { ExternalMonthlyPlanDto, AuditFlowScope } from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "externalMonthPlanList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatDate(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    },
  },
})
export default class externalMonthPlanList extends Vue {
  queryForm: any = {
    date: undefined,
    displayName: "",
  };

  created() {
    if (this.$route.query.date) {
      this.queryForm.date = this.$route.query.date;
    }
  }

  fetchData(params: any) {
    return api.externalMonthlyPlanService.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;
      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "externalMonthPlanCreate",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalMonthPlanEdit",
      params: {
        id: row.id,
      },
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalMonthPlanDetail",
      params: {
        id: row.id,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: ExternalMonthlyPlanDto) {
    // await api.externalMonthlyPlanService
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  // 删除
  async handleDelete(index: number, row: ExternalMonthlyPlanDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalMonthlyPlanService
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalMonthlyPlan,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
